@import './vars';

/**
 * Button
 */

.space-3-btn-primary {
  color: $TEXT_COLOR_DARK !important;

  &[disabled] {
    border: transparent;
    color: $SECONDARY !important;
    background: $BG_CONTENT_LIGHT;
  }
}

.login-modal-provider {
  .ant-modal-content {
    background-color: #070d0e;
    padding: 0px;

    input {
      background-color: #060708;
      border: 1px solid #4d5a66;
      color: #ffffffe0;

      &:focus {
        border-color: #35e8d9;
        box-shadow: 0 0 0 2px #5bf5ef21;
        border-inline-end-width: 1px;
      }

      &::placeholder {
        color: #444546;
      }
    }

    .ant-row {
      font-family: Archivo, sans-serif;
    }

    .ant-typography {
      font-family: Archivo, sans-serif;
    }

    .ant-btn-link {
      color: #0fdbd1;
      &:hover {
        color: #5ff5e3;
      }
    }

    .ant-btn-primary {
      background: #0fdbd1;
      color: #1e1e1e;

      &:hover {
        background-color: #35e8d9;
        color: #1e1e1e;
      }

      &:disabled {
        border: #0000;
        color: #4d5a66 !important;
        background: #1c2023;
      }
    }
  }
  .ant-typography {
    color: #ffffffe0;
  }
}

/**
 * Notification
 */

.space-3-notification {
  &-notice {
    background: $BG_CONTENT_LIGHT !important;
    border-radius: 6px !important;

    &-success,
    &-success-outline {
      .space-3-notification-notice-message,
      .space-3-notification-notice-icon-success {
        color: $PRIMARY !important;
      }

      &-outline {
        &:before {
          background: url('/images/border-notice-success.svg') no-repeat center;
        }
      }

      &-contained {
        background: $SUCCESS_DARK !important;
      }
    }

    &-warning,
    &-warning-outline {
      .space-3-notification-notice-message,
      .space-3-notification-notice-icon-warning {
        color: $WARNING_LIGHT !important;
      }

      &-outline {
        &:before {
          background: url('/images/border-notice-warning.svg') no-repeat center;
        }
      }

      &-contained {
        background: $WARNING_LIGHT !important;
      }
    }

    &-error,
    &-error-outline {
      .space-3-notification-notice-message,
      .space-3-notification-notice-icon-error {
        color: $DANGER !important;
      }

      &-outline {
        &:before {
          background: url('/images/border-notice-error.svg') no-repeat center;
        }
      }

      &-contained {
        background: $DANGER !important;
      }
    }

    &-info,
    &-info-outline {
      .space-3-notification-notice-message,
      .space-3-notification-notice-icon-info {
        color: $TEXT_COLOR_INFO !important;
      }

      &-outline {
        &:before {
          background: url('/images/border-notice-info.svg') no-repeat center;
        }
      }

      &-contained {
        background: $TEXT_COLOR_INFO !important;
      }
    }

    &-message {
      margin-bottom: 0 !important;
    }

    .space-3-notification-notice-close {
      background: transparent;
      border-radius: 50% !important;
      width: 24px !important;
      height: 24px !important;

      .space-3-notification-close-x {
        color: $SECONDARY;
        font-size: 14px;
      }

      &:hover {
        background: #a0a0a010 !important;
      }
    }

    &-with-icon:not(:has(.space-3-notification-notice-description:empty)) {
      .space-3-notification-notice-icon {
        margin-top: 5px;
      }
    }
  }

  // outline

  div[class*='outline'] {
    overflow: inherit !important;
    position: relative;

    &:before {
      position: absolute !important;
      content: '';
      top: -1px;
      left: -1px;
      width: calc(100% + 2px) !important;
      height: calc(100% + 2px) !important;
      background-size: 100% 100%;
    }
  }

  div[class*='contained'] {
    *:not(.space-3-notification-close-x):not(.space-3-notification-close-x *) {
      color: $TEXT_COLOR_PRIMARY !important;
    }
  }

  div[class*='contained'] {
    *:not(.a8-notification-close-x):not(.a8-notification-close-x *) {
      color: $TEXT_COLOR_PRIMARY !important;
    }
  }
}
