.disclaimer-container {
  .space-3-modal-content {
    padding: 0;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(10, 12, 14, 0.6);
    backdrop-filter: blur(8px);
  }
  .disclaimer-header {
    background: rgba(255, 255, 255, 0.12);
  }

  .disclaimer-footer {
    background: rgba(255, 255, 255, 0.04);
  }
}
