@import '@/theme/a8/vars';

.disclaimer-a8-container {
  .a8-modal-content {
    padding: 0;
    border-radius: 16px;
    background: #0a0f06;
    backdrop-filter: blur(8px);
    box-shadow:
      0.5px -0.5px 2px 0px rgba(148, 194, 81, 0.6) inset,
      -1px 1px 2px 0px rgba(228, 255, 189, 0.6) inset;
  }

  .disclaimer-header--text {
    font-size: 24px;
    color: #ceff70;
  }

  .disclaimer-footer {
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: -28px;
      transform: translateX(-50%);
      width: 90%;
      height: 35px;
      margin: auto;
      background: linear-gradient(180deg, rgba(10, 15, 6, 0) 0%, #0a0f06 100%);
    }

    .btn-action {
      font-style: normal;
      color: #072b09;
      background: linear-gradient(245deg, #b0e760 38.32%, #5a7b2a 97.11%);
      box-shadow:
        0.5px -0.5px 2px 0px rgba(148, 194, 81, 0.6) inset,
        -1px 1px 2px 0px rgba(228, 255, 189, 0.6) inset;
      backdrop-filter: blur(22px);
      border: none;

      &:hover {
        background: linear-gradient(
          191deg,
          rgb(228, 255, 189) 0%,
          rgb(217, 255, 118) 100%
        );
        color: rgb(7, 43, 9);
      }
    }
  }

  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .disclaimer-header--text {
      font-size: 18px;
    }
  }
}
