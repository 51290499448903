@import '@/theme/vars';

.a8-login-modal-email-otp {
  display: inline-flex;
  height: 624px;
  padding: 40px;
  width: 400px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid $SECONDARY;
  background: $BG_PRIMARY;
  text-align: center;

  .brand-logo {
    width: 100%;
    height: 66px;
    padding: 20px 30px;
  }

  .verify-email {
    color: $PRIMARY;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%; /* 27px */
    letter-spacing: 0.2px;
  }
  .email-address {
    font-size: 16px;
    font-weight: 600;
    line-height: 125%; /* 20px */
    letter-spacing: 0.2px;
  }
  .resend-code {
    color: $PRIMARY;
    font-size: 14px;
    font-weight: 700;
    line-height: 142%; /* 19.88px */
    letter-spacing: 0.2px;
  }
  .email-input {
    width: 100%;
    height: 56px;
  }
}
