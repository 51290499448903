@import './vars';

//Unset transition for tag a to prevent conflict with antd css
a {
  transition: unset !important;
}

.space-3-typography,
.a8-typography {
  margin: 0 !important;
}

.title-expanded {
  font-stretch: expanded !important;
  font-weight: 300 !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-gradient {
  background: linear-gradient(
    90deg,
    #0fdbd1 5.33%,
    #c7fffc 34.4%,
    #2fccc5 72.38%,
    #b9f6f3 95.36%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-ellipsis {
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.caption {
  font-size: 12px;
}

.caption-table {
  font-size: 12px;
}

.text-secondary {
  color: $SECONDARY !important;
}

.white {
  color: $TEXT_COLOR_PRIMARY !important;
}

.title-modal {
  &--warning {
    color: $WARNING_LIGHT !important;
  }
  &--error {
    color: $DANGER !important;
  }
}

.warning-light {
  color: $WARNING_LIGHT;
}

.disable-light {
  color: $DISABLE_LIGHT;
}

.multiplier-text-gradient {
  background: linear-gradient(90deg, #fec291 4.98%, #ff7300 90.6%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-warning {
  background: linear-gradient(90deg, #fff5ed 0%, #ff7300 44%, #e8bb96 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
