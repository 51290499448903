@import '@/theme/vars';
@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin custom-btn-primary {
  padding: 0 40px;
  height: 44px;

  span {
    line-height: 142%;
    font-weight: 700;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  // Disabled scrollbar style for firefox
  //scrollbar-width: thin;
  //scrollbar-gutter: both-edges;
  //scrollbar-color: adjust-color($PRIMARY, $alpha: -0.4)
  //  adjust-color($PRIMARY, $alpha: -0.9);
}

*:focus {
  outline: none !important;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative !important;
  font-family: Archivo, sans-serif;
  background: $BG_PRIMARY;
}

.space-3-layout {
  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    .no-padding {
      margin-left: -16px;
      margin-right: -16px;
    }
  }
}

.font-archivo-expanded {
  font-stretch: expanded;
  font-family: 'Archivo', sans-serif;
}

.scrollbar {
  overflow: auto; // To let lazyload realize the container
  &:hover {
    overflow: overlay; // Avoid scrollbar occupying space
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-btn {
  @include custom-btn-primary;
}

.custom-warning-btn {
  @include custom-btn-primary;
  background: $WARNING_LIGHT !important;

  &[disabled] {
    background: $BG_CONTENT_LIGHT !important;
  }
}

.tooltip-sp3 {
  border: 1px solid #0bcbc2;
  border-radius: 6px;

  .space-3-tooltip-inner {
    background-color: #2a3138 !important;
    border-radius: 6px !important;
  }
  .space-3-tooltip-arrow {
    &::after {
      background-color: #2a3138 !important;
      border: 1px solid #0bcbc2;
    }
  }
}

/**
* NProgress
*/

#nprogress .spinner {
  display: none !important;
}

#nprogress .bar {
  background: $PRIMARY;
}

#nprogress .peg {
  box-shadow:
    0 0 10px $PRIMARY,
    0 0 5px $PRIMARY;
}

#nprogress .spinner-icon {
  border-top-color: $PRIMARY;
  border-left-color: $PRIMARY;
}

/**
* Table
*/
.custom-table {
  table {
    border-spacing: 0 4px !important;
  }

  .space-3-table-container {
    &:after {
      box-shadow: none !important;
    }
  }

  .space-3-table-body {
    overflow: hidden auto !important;
  }

  thead {
    tr {
      th {
        color: $SECONDARY !important;
        background-color: transparent !important;

        font-size: 16px;
        font-weight: 400 !important;
        letter-spacing: 0.2px;
        border: none !important;
        padding: 0 !important;

        &:nth-of-type(1) {
          padding: 0 14px !important;
        }
        &::before {
          display: none !important;
        }
      }
    }
  }

  tbody {
    tr:first-child td {
      padding: 0 !important;
    }

    tr:not(:first-child) {
      padding: 8px 12px !important;
      background-color: $BG_SECONDARY !important;
      cursor: pointer;

      td {
        border: none !important;
        padding: 8px 0 !important;

        &:nth-of-type(1) {
          padding-left: 8px !important;
          padding-right: 8px !important;
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}

/**
* Archivo
*/

@font-face {
  font-family: Archivo;
  src: url('/fonts/archivo/archivo.ttf');
  font-style: normal;
}

@font-face {
  font-family: Archivo;
  src: url('/fonts/archivo/archivo-italic.ttf');
  font-style: italic;
}

/**
* DIN
*/

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-regular-italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-thin-italic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-extra-light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-extra-light-italic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-light-italic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-medium-italic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-bold-italic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-black-italic.ttf');
  font-weight: 900;
  font-style: italic;
}
